/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
@supports not (-ms-high-contrast: none) {
  .app-body[dir="rtl"] {
    /*Apply to other browsers but IE */ }
    .app-body[dir="rtl"] .first-column .featured-grid-carousel-container {
      margin-top: 4px; }
    .app-body[dir="rtl"] .featured-item.desk4Img, .app-body[dir="rtl"] .featured-item.desk5Img {
      margin-top: 4px; } }

.featured-grid-container {
  direction: ltr;
  display: -ms-flexbox;
  display: flex;
  background-color: #0F1822;
  -ms-flex-pack: center;
  justify-content: center; }
  .featured-grid-container .image-thumbnail .carousel-image img {
    display: block;
    width: 100%;
    border-radius: 30px; }
  .featured-grid-container .image-thumbnail .play-button {
    width: 75px;
    height: 75px; }
    .featured-grid-container .image-thumbnail .play-button .icon {
      width: 75px;
      height: 75px; }
  .featured-grid-container .first-column .featured-grid-carousel-container {
    margin-top: 4px; }
  .featured-grid-container .third-column .featured-grid-carousel-container {
    margin-bottom: 4px; }
  .featured-grid-container .featured-grid-carousel-container .play-button {
    width: 115px;
    height: 115px; }
    .featured-grid-container .featured-grid-carousel-container .play-button .icon {
      width: 115px;
      height: 115px; }
  .featured-grid-container .featured-grid-carousel-container .Horizontal-menu {
    width: auto; }
  .featured-grid-container .featured-grid {
    opacity: 1;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-pack: center;
    justify-content: space-between;
    transition: opacity 1s ease;
    width: calc(((100vh - 78px) / 3) * 5.8 + 16px);
    max-width: calc(100vw - 20px);
    height: calc(100vh - 70px);
    max-height: calc((100vw - 10px) / 5 * 3);
    margin-top: 110px; }
    .featured-grid-container .featured-grid .first-column {
      -ms-flex-direction: column;
      flex-direction: column;
      width: calc(38% - 4px);
      height: 100%;
      display: flex;
      gap: 37px; }
      .featured-grid-container .featured-grid .first-column .featured-item.desk1Img {
        margin-right: 4px; }
    .featured-grid-container .featured-grid .second-column {
      -ms-flex-direction: column;
      flex-direction: column;
      display: flex;
      gap: 36px;
      width: calc(17.8% - 4px);
      height: 100%;
      margin: 0px 37px; }
      @media screen and (min-width: 992px) and (max-width: 1367px) and (orientation: landscape) {
        .featured-grid-container .featured-grid .second-column {
          width: calc(17.7% - 4px);
          gap: 35px; } }
      @media screen and (min-width: 992px) and (max-width: 1367px) and (orientation: portrait) {
        .featured-grid-container .featured-grid .second-column {
          width: calc(17.2% - 4px);
          gap: 37px; } }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        .featured-grid-container .featured-grid .second-column {
          margin: 0px 35px; } }
      .featured-grid-container .featured-grid .second-column .featured-item.desk4Img, .featured-grid-container .featured-grid .second-column .featured-item.desk5Img {
        margin-top: 4px; }
      .featured-grid-container .featured-grid .second-column .featured-item.desk7Img, .featured-grid-container .featured-grid .second-column .featured-item.desk8Img {
        margin-top: 4px; }
    .featured-grid-container .featured-grid .third-column {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 37px;
      width: calc(38% - 4px);
      height: 100%; }
      .featured-grid-container .featured-grid .third-column .featured-item.desk7Img {
        margin-right: 4px; }
    .featured-grid-container .featured-grid .grid-arrow {
      position: absolute;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
      height: 150px;
      bottom: -45px;
      left: 0;
      z-index: 20;
      cursor: pointer;
      pointer-events: none; }
      .featured-grid-container .featured-grid .grid-arrow .arrow-container {
        animation-iteration-count: infinite;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 300px;
        height: 150px;
        pointer-events: all;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.8) 0, transparent 75%);
        animation: jump 1.5s ease 1s 3 normal; }
        .featured-grid-container .featured-grid .grid-arrow .arrow-container .grid-arrowImg {
          background: url("~app/resources/assets/smart-layout/grid-arrow.png");
          display: inline-block;
          width: 51px;
          height: 51px;
          transform: scale(1.1); }
      .featured-grid-container .featured-grid .grid-arrow.hidden {
        display: none; }
  .featured-grid-container .Horizontal-menu .image-thumbnail .play-button {
    width: 115px;
    height: 115px; }
    .featured-grid-container .Horizontal-menu .image-thumbnail .play-button .icon {
      width: 115px;
      height: 115px; }

.featured-cover-container {
  display: none;
  -ms-flex-pack: center;
  justify-content: center; }
  .featured-cover-container .image-thumbnail img {
    display: block; }
  .featured-cover-container .featured-cover {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    transition: opacity 1s ease;
    opacity: 1;
    width: 100%; }
    .featured-cover-container .featured-cover .first-row {
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      overflow: hidden; }
      .featured-cover-container .featured-cover .first-row .featured-custom-cover-carousel {
        width: 100%; }
        .featured-cover-container .featured-cover .first-row .featured-custom-cover-carousel .image-thumbnail img:not(.icon) {
          max-width: 100%; }
        .featured-cover-container .featured-cover .first-row .featured-custom-cover-carousel .image-thumbnail .play-button {
          width: 90px;
          height: 90px; }
          .featured-cover-container .featured-cover .first-row .featured-custom-cover-carousel .image-thumbnail .play-button .icon {
            width: 90px;
            height: 90px; }
    .featured-cover-container .featured-cover .featured-custom-cover-mobile {
      overflow: hidden; }
      .featured-cover-container .featured-cover .featured-custom-cover-mobile .image-thumbnail .play-button .icon {
        height: 50px;
        width: 50px; }

@media only screen and (max-width: 1023px) and (min-width: 767px) {
  .featured-grid-container .featured-grid .grid-arrow {
    display: none; } }

@media only screen and (max-width: 745px) {
  .featured-cover-container {
    display: flex;
    margin-top: 50px; }
    .featured-cover-container .featured-cover .first-row {
      margin-bottom: 0px; }
    .featured-cover-container .featured-custom-cover-carousel .swiper-container {
      width: 100%;
      margin: 0; }
    .featured-cover-container .featured-custom-cover-mobile {
      margin-top: 4px; }
      .featured-cover-container .featured-custom-cover-mobile .swiper-container {
        width: calc(100% - 8px);
        margin: 0 4px 0 4px; }
  .featured-grid-container {
    display: none; } }

@media only screen and (min-width: 626px) and (max-width: 745px) {
  .featured-custom-cover-carousel {
    margin-bottom: 4px; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .featured-grid-container .featured-grid {
    width: calc(((85vh - 78px) / 3) * 5 + 16px);
    height: calc(90vh - 70px); } }

@media screen and (max-width: 450px) and (orientation: landscape) {
  .featured-cover-container .featured-cover .first-row .featured-custom-cover-carousel .image-thumbnail img:not(.icon) {
    height: 57vh;
    width: auto; }
  .featured-cover-container .carousel-image {
    display: flex;
    justify-content: center; }
  .featured-cover-container .featured-custom-cover-carousel .image-thumbnail img {
    height: 100%; } }
