/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss";

 .subscribe-to-watch-container {
   font-family: "changa";
   padding-bottom: 41px;
  }
 .app-body[dir="rtl"] {
   .subscribe-to-watch-container {
     font-family: "changa";
     padding-bottom: 41px;
 
     .subscribe-back {
       margin: 105px 40px 0 0;
 
       .back-button {
         transform: rotate(0deg);
         
       }
     }
   }
 }
 
 .subscribe-to-watch-container {
   min-height: calc(100vh - 245px);
  //  background: #000;
  background-color: #0F1822;
   color: #ffffff;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   font-family: "changa";
 
   .subscribe-back {
     position: absolute;
     margin: 105px 0 0 105px;
     z-index: 99;
 
     .back-button {
       height: 46px;
       padding: 0;
       transform: rotate(180deg);
 
       &:hover {
         cursor: pointer;
       }
     }
 
     .back-text {
       height: 37px;
       line-height: 37px;
       font-size: 18px;
       margin: 5px;
 
       &:hover {
         background: transparent;
         cursor: pointer;
       }
     }
   }
 
   .subscribe-to-watch-container-text {
     display: flex;
     justify-content: center;
     align-items: center;
     min-height: calc(87vh - 181px);
     flex-direction: column;
 
     .subscribe-title {
       color: #ffffff;
       font-size: 21px;
       @media only screen and (max-width: $mobile-view-threshold-px) {
         font-size: 18px;
       }
     }
 
     .subscribe-btn-1 {
       width: 137px;
       height: 53px;
       margin-top: 30px;
       line-height: 53px;
       border-radius: 5px;
       background-color: #ffffff;
       color: #ff6d02;
       padding: 0;
       font-size: 16px;
       @media only screen and (max-width: $mobile-view-threshold-px) {
         font-size: 20px;
       }
     }
     @media only screen and (max-width: $mobile-view-threshold-px) {
       min-height: calc(100vh - 235px);
     }
   }
 }
 