/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.gridScreen {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .gridScreen .section-title {
    display: none;
    font-size: 1.563em;
    color: #ff6d02;
    margin: 140px 60px 0 !important; }
  @media only screen and (min-width: 768px) {
    .gridScreen .select-box-container {
      margin-top: 22px !important; } }
  .gridScreen .listing-filters-mobile {
    display: block;
    position: absolute;
    width: 100%;
    height: 50px;
    background-color: #ebebeb;
    color: #9993a0;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 5; }
    .gridScreen .listing-filters-mobile .container {
      display: table;
      white-space: nowrap;
      overflow-x: auto;
      width: 100%;
      text-align: center; }
      .gridScreen .listing-filters-mobile .container .filter-item {
        display: table-cell;
        padding: 0 12px;
        font-size: 1em;
        line-height: 50px;
        cursor: pointer; }
        .gridScreen .listing-filters-mobile .container .filter-item.selected {
          color: #ff740f; }
  .gridScreen .listing-filters {
    margin: 20px 0;
    /*     .select-box-container {
      margin: 10px 0 10px 30px;
      display: -ms-inline-flexbox;
      display: inline-flex;

      .select-box {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 250px;
        cursor: pointer;
        background-color: $grid-dropdown-background-color;
        color: $grid-dropdown-text-color;
        z-index: 4;

        .selected,
        .select-element {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
          flex-direction: row;
          -ms-flex-pack: justify;
          justify-content: space-between;
          line-height: 46px;
          height: 46px;
          &:hover {
            color: $grid-dropdown-element-hover-color;
          }
        }
        &.single {
          cursor: unset;
          user-select: none;
          .selected {
            &:hover {
              color: $grid-dropdown-text-color;
            }
          }
        }
      }

      label {
        height: 46px;
        line-height: 46px;
        float: left;
      }

      .selected {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 3;

        .btn {
          .icon {
            width: 25px;
            height: auto;
            transition: transform 0.2s ease;
            top: 0px;
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
    } */
    /*     select-box:first-child {
      display: none;
    }

    select-box:last-child {
      .select-box-container {
        margin-top: 50px;
      }
    }

    select-box {
      .select-box-elements {
        height: 0px;
        position: absolute;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        top: 46px;
        padding: 0 10px;
        transition: height 0.3s ease;
        background-color: #ebebeb;
        overflow: hidden;
        transition: height 0.3s ease;
      }
      .select-element {
        transition: opacity 0.2s ease;
        border-top: solid 1px rgba(255, 255, 255, 0.75);
        opacity: 0;
      }

      &.open {
        .select-box-elements {
          height: auto;
        }
        .select-element {
          opacity: 1;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }

        .btn {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    } */ }
    .gridScreen .listing-filters h1 {
      font-size: 1.3em;
      color: #ff6d02;
      display: none; }
    @media screen and (max-width: 745px) {
      .gridScreen .listing-filters .first-select-box {
        visibility: hidden; } }
  .gridScreen .grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    clear: both;
    gap: 42px; }
    .gridScreen .grid .carousel-item {
      width: 50%;
      transition: transform 0.3s ease;
      margin: 2px 0; }
      .gridScreen .grid .carousel-item .image-thumbnail:hover {
        -webkit-transform: scale(1.015);
        -moz-transform: scale(1.015);
        -ms-transform: scale(1.015);
        -o-transform: scale(1.015);
        transform: scale(1.015); }
    .gridScreen .grid .default-image,
    .gridScreen .grid img {
      width: calc(100% - 4px);
      display: block;
      border-radius: 30px; }
      @media screen and (max-width: 767px) {
        .gridScreen .grid .default-image,
        .gridScreen .grid img {
          border-radius: 15px; } }
    .gridScreen .grid .btn,
    .gridScreen .grid .carousel-bottom-overlay,
    .gridScreen .grid .on-image-desc,
    .gridScreen .grid .overlay-color {
      display: none; }

@media screen and (max-width: 767px) {
  .app-body[dir="rtl"] .listing-filters .select-box-container label {
    float: right;
    margin: 0;
    color: #ea7528;
    font-family: 'changa-regular'; }
  .gridScreen .listing-filters .select-box-container {
    display: block;
    margin-left: 30px;
    margin-right: 30px; }
    .gridScreen .listing-filters .select-box-container label {
      float: none;
      margin: 0;
      color: #ea7528;
      font-family: 'changa-regular'; }
  .gridScreen .grid {
    width: calc(100% - (15px * 2));
    margin: 0 15px;
    padding: 0;
    gap: 15px; }
    .gridScreen .grid .carousel-item {
      width: 43%; }
  .gridScreen .grid .carousel-item {
    width: 30%; } }

@media (min-width: 745px) {
  .gridScreen .section-title {
    display: block;
    font-weight: bold;
    width: calc(100% - (60px * 2));
    margin: 40px 60px 0; }
  .gridScreen .listing-filters-mobile {
    display: none; }
    .gridScreen .listing-filters-mobile .filter-item {
      display: table-cell;
      padding: 0 12px;
      font-size: 1em;
      line-height: 50px;
      cursor: pointer;
      color: #818181;
      font-size: 16px;
      font-family: 'changa-regular'; }
  .gridScreen .listing-filters {
    width: calc(100% - (60px * 2));
    margin-left: 60px;
    margin-right: 60px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    float: none;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .gridScreen .listing-filters h1 {
      display: block; }
    .gridScreen .listing-filters .select-box-container {
      display: -ms-flexbox;
      display: flex;
      margin: 0; }
      .gridScreen .listing-filters .select-box-container label {
        margin: 0 20px;
        color: #ea7528; }
    .gridScreen .listing-filters select-box:first-child {
      display: block; }
    .gridScreen .listing-filters select-box:last-child .select-box-container {
      margin-top: 0; }
  .gridScreen .grid {
    width: calc(100% - (60px * 2));
    margin: 0 60px;
    padding: 0; } }

@media (min-width: 1280px) {
  .gridScreen .grid .carousel-item {
    width: 14%; } }

@media (min-width: 1920px) {
  .gridScreen .grid .carousel-item {
    width: 14.666%; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .gridScreen .grid .carousel-item {
    width: 23%; }
  .gridScreen .grid {
    gap: 15px; } }

@media screen and (min-width: 1024px) and (max-width: 1367px) {
  .gridScreen .grid .carousel-item {
    width: 23%; }
  .gridScreen .grid {
    gap: 15px; } }

.gridScreen .firstFolderBanner {
  margin-top: 60px;
  position: relative;
  top: 2em; }
  @media (max-width: 1440px) {
    .gridScreen .firstFolderBanner {
      padding: 10px;
      position: relative;
      top: 3em; } }

@media only screen and (max-width: 767px) {
  /* Styles for mobile portrait */
  .default-image img {
    border-radius: 20px !important; } }
