/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

//  Slick CSS Start

.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px;}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */

// .slick-slide.slick-active.slick-cloned , .slick-slide.slick-active.slick-center.slick-current, .slick-slide.slick-active{
//   width: 365px !important;
// }
.slick-slider.slick-initialized{
  margin: 0 60px;
  @media screen and (min-width: 768px) and (max-width: 991px){
    margin: 0 10px;
  }
}
// .slick-track{
//   gap:40px;
//   @media screen and (min-width: 768px) and (max-width: 991px){
//     gap: 0;
//   }
// }
.slick-carousel{
  margin-top: 60px;
  @media screen and (min-width: 992px) and (max-width: 1024px) {
    margin-right: 0 !important;
    margin-left: 0 !important;
    white-space: nowrap;
  }
  // @media screen and (min-width: 768px) and (max-width: 991px) {
  //   margin: 0 0!important;
  // }
}

.slick-slider {
  position: relative;
  img {
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }
  .slick-prev {
    position: absolute;
    left: 30px;
    top: 0;
    z-index: 9;
    font-size: 0;
    height: 97%;
    width: 48px;
    border: 0;
    // background-color: rgba(0, 0, 0, 0.3);
    background-image: url("~app/resources/assets/thumbnail/ic-left-arrow.png");
    background-repeat: no-repeat;
    background-position: 10px center;
    cursor: pointer;
    background-color: transparent;
  }
  .slick-next {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    font-size: 0;
    height: 97%;
    width: 48px;
    border: 0;
    // background-color: rgba(0, 0, 0, 0.3);
    background-image: url("~app/resources/assets/thumbnail/ic-right-arrow.png");
    background-repeat: no-repeat;
    background-position: 18px center;
    cursor: pointer;
    background-color: transparent;
  }
  .lag_rus, .lag_eng {
    position: absolute;
    bottom: 25px;
    width: 100% !important;
    text-align: center;
    font-size: 24px !important;
    color: #ffffff;
    line-height: 20px !important;
    height: inherit !important;
    display: none;
    padding: 6px;
    @media screen and (min-width: 992px) and (max-width: 1024px) {
      font-size: 17px !important;
      white-space: break-spaces;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      font-size: 17px !important;
    }
    @media (max-width: 767px){
      font-size: 11px !important;
      bottom: 0px;
    }
  }
  .column1 {
    position: relative;
  }
}
.rtl .lag_rus {
  display: block;
  width: 211px;
  height: 69px;
  // opacity: -1;
  color: #ffffff;
  font-family: "Changa";
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
}
.ltr .lag_eng  {
  display: block;
  width: 211px;
  height: 69px;
  // opacity: -1;
  color: #ffffff;
  font-family: "Changa";
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  // font-family: "changa";
  // width: 177px;
}

// Slick CSS End
 
.home-content {
  background-color:#000000;
  .carousel-item {
    .desription {
      white-space: normal;
    }
  }
  .bucket-item-container {
    padding: 0.05px; //to prevent collapsing margin
    &:last-child {
      //  margin-bottom: 50px;
    }
    .column {
      float: left;
      width: 226px;
      padding: 50px;
    }
    
    /* Clearfix (clear floats) */
    .row::after {
      content: "";
      clear: both;
      display: table;
    }
  }
}

.pageTitle {
  margin: 20px;
 font-size: 1.25em;
 margin-left: 85px;
 margin-right: 60px;
 color: #818181;
 font-family: "changa";
 position: relative;
 top: 79px;
 @media(max-width:768px){
  margin-left: 30px;
  margin-right: 20px;
 }
}
/* slick carousel alignment for more than 6 carousels*/

.slick-carousel-desktop{
  margin-left: 60px;
  margin-right: 60px;
}
.slick-carousel-mobile{
  margin-left: 20px;
  margin-right: 20px;
}

.slick-slider img{
  border-radius: 30px;
 
}

.slick-track{
  display: flex;
  // gap: 48px !important;
}
.slick-slider img{
  border-radius: 20px;
 
}

.slick-slide {
  margin-left:30px;
  // @media only screen and (max-width: 767px){
  //   margin-left:10px;
  // }
  @media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation:landscape){ 
    margin-right:20px;
    margin-left: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){ 
    margin-left:14px;
    margin-right: 14px;
  }
  @media(max-width:767px){
    margin-left: 5px;
  }
}

.slick-list [data-slick-index="0"] {
   margin-left: 0;
 }

@media screen and (min-width: 992px) and (max-width: 1024px){
  .slick-track{
   
    // gap: 30px !important;
  }
}

/*Device width pixel lesser than 768*/
@media only screen and (max-width: 769px) {
  .scroll-menu-arrow {
    display: none;
  }
  .slick-track{
    // gap: 8px !important;
  }
  .slick-slider.slick-initialized{
    // margin: 0;
    margin: 0 7px;
  }
}
.firstFolderBanner{
  // padding: 10px;
  position: relative;
  top: 7em;
  @media(max-width:1440px){
    padding: 10px;
    position: relative;
    top: 9em;

  }
  @media(max-width:767px){
    padding: 10px;
    top:2em;
    
  }

}

// .slick-slider.slick-initialized {
//   z-index: 9;
// }


.slick-carousel-desktop,.slick-carousel{
  margin-top:  160px;
  margin-left: -8px;
  margin-right: 14px;
  @media(max-width:745px){
    margin-top:  25px;
  }
  }
  
  .bucketItem{
    margin-left: 25px;
    margin-right: 21px;
  
  }
// }

// @media screen  and (max-width:768px) and (max-width:991px) and (orientation:portrait){
//   .slick-slider.slick-initialized{
//    margin: 0 5px !important;
//   }

// }