/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss";

 .headerContent {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   width: calc(99% / 3);
   height: inherit;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
 }
 
 .header {
  padding-top: 20px;
  background: transparent;
  // background-image: linear-gradient(180deg, rgba(24, 29, 37, .8) 2%, rgba(24, 29, 37, 0) 66%);
  // background-image: linear-gradient(180deg, rgba(24, 29, 37, 0.8), rgba(24, 29, 37, 0));
  // background-image: linear-gradient(180deg, #0F1822, rgba(24, 29, 37, 0));
    // height: 200px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: background-color 0.3s ease; 
     &:after {   
      background-image: linear-gradient(180deg, #0F1822, rgba(24, 29, 37, 0));    
      transition: background-color 0.3s ease;
      content: "";
      width: 100%;
      position:absolute;
      top: 0;
      left: 0;
      height: 100px;
      z-index: -1;
      @media(max-width:745px){
        height: 80px;
      }
     }
 @media(max-width:745px){ 
   // background: transparent linear-gradient(180deg, #0f1822 0%, #0f182200 100%) 0% 0% no-repeat padding-box;
   padding-top: 20px;
   height: 80px;
  // background-image: linear-gradient(180deg, rgba(24, 29, 37, .8) 2%, rgba(24, 29, 37, 0) 98%);
  
   // display: none;
 }

   .install_app {
     height: 90px;
     padding: 5px 16px;
     display: flex;
     justify-content: space-between;
     background-color: #ffffff;
     z-index: 10000;
     // margin-top: -18px;
     //  position: fixed;
  @media(max-width:767px){
    position: relative;
  }
     .closeicon {
       display: inline-block;
       width: 20px;
 
       img {
         position: relative;
         top: 26px;
       }
     }
 
     .alert_logo {
       display: inline-block;
       margin-right: 10px;
       z-index: 1;
 @media(max-width:767px){
   z-index: 0;
 }
       img {
         position: relative;
         top: 8px;
         height: 60px;
       }
     }
 
     .alert_content {
       display: inline-block;
       flex-grow: 1;
 
       p {
         margin: 0;
       }
 
       .alert_title {
         color: #26292d;
         font-size: 15px;
       }
 
       .alert_sbtitle {
         color: #93939a;
         font-size: 11px;
       }
 
       .alert_star {
         height: 20px;
 
         img {
           display: inline-block;
           margin-right: 2px;
         }
       }
 
       .get_app {
         color: #93939a;
         font-size: 13px;
       }
 
       .get_app_play {
         color: #93939a;
         font-size: 13px;
       }
     }
 
     .alert_view {
       // display: inline-block;
       a {
         color: #4593fc;
         font-size: 18px;
         text-decoration: none;
         position: relative;
         top: 20px;
       }
     }
   }
 }
 .header.onscroll {
  // background-color: red !important; 
  background: transparent;
  // background-image: linear-gradient(180deg, #1f252e, #1f252e);
  background: #0f1822;
  height:90px;
  @media(max-width:745px){
    height: 50px;
    background: transparent;
  // background-image: linear-gradient(180deg, #1f252e, #1f252e);
  background: #0f1822;
  }
}
 .header_bottom {
   display: flex;
   /* justify-content: space-between; */
  //  height: 90px;
   // padding: 0px 0px 0px 45px;
   padding: 0px 62px;
   width: 100%; 

   @media (min-width:768px) and (max-width:1023px){
    padding: 0px;
   }
 @media(max-width:1440px){
   .header_bottom {
   padding: 0px 0px 0px 20px;
   }
 }
   @media (max-width: 745px) {
       
       display: flex;
      padding: 0px !important;
       height: 100vh;
    
     .header {
       height: 100%;
     }
   }
 
   .contentLeft {
     display: flex;
     width: 32%;
     height: inherit;
     box-sizing: border-box;
     align-items: center;
     /* justify-content: space-between; */
     position: relative;
     justify-content: flex-end;
     margin-left: auto;
     //  right: 55px;
  @media(max-width:1023px){
    width: 30%;
    // background-color: green;
  }
     @media screen and (max-width: 1440px) and (min-width: 746px) {
       // width: 35%;
       width:20%;
       // justify-content: flex-start;
       left: 0;
       right: 0;
 &:has(.subscribe-btn){
   width:34%;
 }
 @media(max-width:1366px) and (min-width:768px) and (orientation: portrait){
  width: 35% !important;
  &:has(.subscribe-btn){
    width:35% !important
  }

 }
     }
     @media (max-width: 745px) {
       // position: fixed;
       // width: 100px;
       // left: 330px;
       // right: 378px;
       // position: fixed;
       width: 100%;
       left: 0;
       right: 378px;
       // display:block;
     }
   }
 
   .contentMiddle {
 
     display: flex;
     //  width: 10%;
     height: inherit;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     text-align: center;
 @media(max-width:745px){
   display:block;
 }
     .logo {
       width: 150px;
       height: 39px;
     }
   }
   .languagebtn-container {
     margin: 0px 21px;
     @media(max-width:745px){
       display: none;
     }
   }
   .contentRight {
     // @extend .headerContent;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     width: 54%;
     height: inherit;
     box-sizing: border-box;
     align-items: center;
     text-align: end;
   
     @media(max-width:1440px){
       // width: 50% !important;
       width:43% !important;
       
     }
     @media(max-width: 1023px) and (min-width:768px){
      width: 52% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;     
      
     }
    //  @media only screen and(max-width:1366px) and (min-width: 768px) {
   
    //   width: 54% !important;
    //  }
   }
 .rtl  .contentRight {
   // display: flex;
   // width: 49%;
   // height: inherit;
   // box-sizing: border-box;
   // align-items: center;
   // text-align: end;
   @media(max-width: 1440px){
     // width: 45%;
     width:44% !important;
   }
   @media (max-width: 1023px) and (min-width:768px) {
        width: 50% !important;
        padding-left: 0px !important;
        padding-right: 0px !important
    
}
 }
 }
 .rtl {
    .header_bottom{
   display: flex;
   justify-content: space-between;
   height: 90px;
   // padding: 0px 0px 0px 45px;
   padding:0px 62px;
   width: 100%;
   // gap: 6.5%;  
   @media (min-width:768px) and (max-width:1023px){
    padding: 0px;
    height: auto;
   }
 }
  .contentLeft {
   // justify-content: flex-start;
   /* right: 0px !important; */
   // left: -215px;
   right: 0px;
   margin-right: auto;
   margin-left: 0;
   @media(max-width:1440px){
     // right: -30px;
     // width:32% !important; 
   }
   @media(max-width:745px){
     right:0px;
     // width: unset !important
   }
 }
 }
 .vertical-separator {
   display: inline-block;
   height: 32px;
   width: 2px;
   margin: -10px 0;
   background: #fff;
 }
 
 @media only screen and (max-width: 745px) {
   .app-body .page-content .home-content {
     margin-top: 70px !important;
     // z-index: 5;
   }
 }
 
 .ar,
 .en {
   display: none;
 }
 
 .rtl .ar {
   display: block;
 }
 
 .ltr .en {
   display: block;
 }
 
 .eninstall,
 .arinstall {
   display: none;
 }
 
 .rtl .arinstall {
   display: block !important;
 }
 
 .ltr .eninstall {
   display: block !important;
 }
 
 .rtl .header .install_app .alert_content {
   padding-right: 5px;
   display: inline-block;
   flex-grow: 1;
 }
 
 .rtl .header .install_app .alert_content .alert_star {
   height: 10px;
 }
 
 .rtl .header .install_app .alert_content .alert_star img {
   position: relative;
   top: -9px;
 }
 
 .head-links {
   // margin: 0px -45px;
   @media (max-width: 767px) {
     display: none;
   }
 
   .activelink {
     color: #fff !important;
     // border-bottom: 3px solid #FF6D02;
    //  background: linear-gradient(30deg, #ee7623 30%, #ec1c24 70%);
     -webkit-background-clip: text;
     color: transparent;
   }
 
   ul {
     padding: 0;
     //  margin: 0 30px 0 0;
     display: flex;
 
     li {
       list-style: none;
       font-size: 16px;
       font-weight: bold;
       margin-left: 70px;
       @media(max-width:1023px) and (min-width:768px){
        margin-left: 10px !important;
       }
       @media(max-width:1440px){
         margin-left: 55px;
       }
       &:first-child {
         // margin-left: 0px;
       }
       &:last-child {
         margin-right: 0;
       }
 
       a {
         color: white;
         text-decoration: none;
         position: relative;
         transition: all 0.3s ease;
         font-family: "Changa";
         white-space: nowrap;
         font: normal normal bold 17px/21px Changa;
   @media(max-width:1023px) and (min-width:768px){
    // font: normal normal bold 14px / 21px Changa;
    font-size: 12px;
   }
         &:hover {
           background: linear-gradient(#ee7623 30%, #ec1c24 70%);
           -webkit-background-clip: text;
           color: transparent;
         }
       }
     }
   }
 }
 
 .rtl .head-links {
   // margin: -45px 0px;
   @media (max-width: 767px) {
     display: none;
   }
 
   ul {
     padding: 0;
     //  margin: 0 10px 0 0;
     display: flex;
 
     li {
       list-style: none;
       font-size: 16px;
       font-weight: bold !important;
       margin-right: 50px;
       margin-left: 0;
       @media(max-width:1023px) and (min-width:768px){
        margin-left: 10px !important;
        margin-right: 10px !important;
       }
       &:first-child {
         // margin-left: 50px;
       }
       &:last-child {
         // margin-right: 0;
       }
 
       a {
         color: white;
         text-decoration: none;
         position: relative;
         transition: all 0.3s ease;
         font-family: "Changa";
         white-space: nowrap;
         @media(max-width:1023px) and (min-width:768px){
          font-size: 10px;
         }
         &:hover {
           &:hover {
             background: linear-gradient(#ee7623 30%, #ec1c24 70%);
             -webkit-background-clip: text;
             color: transparent;
           }
         }
       }
     }
   }
 }
 
 body {
   background-color: #1c1c1c;
 }
 
 @media (max-width: 767px) {
   .contentMiddle,
   .contentRight {
     width: 33% !important;
   }
   .contentLeft {
     width: 100%;
     height: 100%;
   }
 }
 
 .head-links-mobile {
   @media (max-width: 767px) {
     display: block;
     margin-left: -30px;
   }
 
   @media (min-width: 601px) {
     display: none;
   }
 
   .activelink {
     color: #fff !important;
     border-bottom: 3px solid #ff6d02;
   }
 
   ul {
     padding: 0;
     margin: 0 30px 0 0;
     display: flex;
 
     li {
       list-style: none;
       font-size: 16px;
       font-weight: bold;
       margin-left: 40px;
       @media only screen and(max-width:767px) {
         font-size: 16px;
       }
 
       &:last-child {
         margin-right: 0;
       }
 
       a {
         color: #818181;
         text-decoration: none;
         position: relative;
         transition: all 0.3s ease;
         font-family: "Changa";
 
         &:hover {
           color: white;
         }
       }
     }
   }
 }
 
 .rtl .head-links-mobile {
   @media (max-width: 767px) {
     display: block;
     margin-right: -17px;
   }
 
   ul {
     padding: 0;
     margin: 0 30px 0 0;
     display: flex;
 
     li {
       list-style: none;
       font-size: 16px;
       font-weight: bold;
       margin-left: 40px;
       @media (max-width: 767px) {
         font-size: 16px;
       }
 
       &:last-child {
         margin-right: 0;
       }
 
       a {
         color: #818181;
         text-decoration: none;
         position: relative;
         transition: all 0.3s ease;
         font-family: "Changa";
 
         &:hover {
           color: white;
         }
       }
     }
   }
 }
 @media only screen and (max-width: 745px) {
   .header {
     height: 100%;
   }
   .install_app {
     display: none;
   }
 }
 @media(max-width: 745px) {
   .contentLeft {
     .select-box-container{
       // top:42em !important;
     }
   //   @media(max-width:320px) and (max-height:658px) {
   //     top: 41em !important;
      
   // }
   // @media(min-width:321px) and (max-width:360px) and (max-height:640px){
   //   top: 40em !important;
   
    
   //  }
   //  @media(min-width:361px) and (max-width:375px) and (max-height:667px){
   //   top: 25em !important;
 
    
   //  }
   }
 }