/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.Premium-layout-container {
  text-align: center;
  width: 100%;
  position: relative; }

.synopsis-container {
  width: 612px;
  left: 63px !important; }

.premium-thumbnail {
  display: flex;
  flex-direction: row;
  margin-left: 10px; }

.premium-thumbnailAr {
  display: flex;
  flex-direction: row;
  margin-right: 10px; }

.synopsis-containerAr {
  width: 612px;
  right: 63px !important; }

.title-images1 {
  filter: brightness(0) invert(1);
  height: 15px; }

.planspage-gradient {
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(15, 24, 34, 0.6559) 36%, #0f1822 80%);
  width: 100vw;
  height: 284px;
  position: relative;
  bottom: 23%; }

.planspage-gradientAr {
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(15, 24, 34, 0.6559) 36%, #0f1822 80%);
  width: 100vw;
  height: 284px;
  position: relative;
  bottom: 23%; }

.parent_container {
  position: absolute;
  bottom: 10%;
  margin-left: 70px;
  width: 612px;
  margin-right: 80px;
  z-index: 1; }

.content-type-image {
  display: flex;
  align-items: center;
  gap: 7px; }
  .content-type-image .content-type-logo {
    filter: brightness(0) invert(1);
    height: 15px; }
  .content-type-image .content-type-name {
    font-size: 20px;
    text-transform: uppercase; }

.synopsis-inner-container {
  color: #ffffff;
  overflow: hidden;
  text-align: left;
  font-family: "Changa-Regular";
  line-height: 25px;
  width: 490px;
  font-size: 18px; }
  @media (max-width: 767px) {
    .synopsis-inner-container {
      display: none; } }

.synopsis-inner-containerAr {
  color: #ffffff;
  overflow: hidden;
  text-align: right;
  font-family: "Changa-Regular";
  line-height: 25px;
  width: 490px;
  font-size: 18px; }
  @media (max-width: 767px) {
    .synopsis-inner-containerAr {
      display: none; } }

.watchnow-more-container {
  display: flex;
  gap: 20px;
  margin-top: 20px; }

.carouseldashes {
  display: flex;
  z-index: 10;
  cursor: pointer;
  justify-content: flex-end;
  position: absolute;
  bottom: 11%;
  left: 0px;
  right: 4%; }

.carouseldashesAr {
  display: flex;
  margin-top: -310px;
  cursor: pointer;
  justify-content: flex-end;
  position: absolute;
  bottom: 11%;
  left: 4%;
  right: 0px;
  z-index: 1; }

.watch-now-button {
  width: 240px !important;
  height: 44px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  text-align: center;
  font: normal normal bold 15px/33px Changa;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.watch-now-buttonAr {
  width: 240px !important;
  height: 44px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
  background-size: cover;
  text-align: center;
  font: normal normal bold 15px/33px Changa;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 30px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.premiumPageSlider {
  height: auto;
  object-fit: cover; }

.premium-logo-image,
.premium-logo-imageAr {
  display: block; }

.premium-more-button {
  left: 296px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: white;
  height: 43px;
  border: none;
  outline: none;
  cursor: pointer; }

.premium-more-morebuttonAr {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: white;
  height: 43px;
  border: none;
  outline: none;
  cursor: pointer; }

h2.premium-contenttitleEn {
  text-align: left; }

.premium-contenttitleEnAR {
  white-space: nowrap;
  font: normal normal bold 60px / 21px Changa;
  text-align: left; }

.premium-contentTitleArabic {
  white-space: nowrap;
  font: normal normal bold 60px / 21px Changa;
  text-align: right; }

img.premiumPageSlider {
  width: 100%; }

.premium-pageD {
  height: 1000px;
  z-index: 9; }

.google-ad.google-ad-small,
.google-ad.google-ad-big {
  display: none; }

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .watchnow-more-container {
    margin-top: 10px; }
  .synopsis-inner-container {
    font-size: 14px;
    line-height: 20px; }
  .premiumPageSlider {
    width: 100%;
    height: auto;
    object-fit: cover; }
  .Premium-layout-container {
    width: 100%;
    height: auto; }
  .parent_container {
    width: 100%; }
  .premium-pageD {
    height: auto !important;
    width: 100% !important; }
  .planspage-gradient {
    margin-top: -27%; }
  .planspage-gradientAr {
    margin-top: -27%; }
  .carouseldashes {
    margin-top: 0;
    bottom: 9%;
    right: 12px; }
  .carouseldashesAr {
    margin-top: 0;
    bottom: 14%;
    left: 12px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .premium-logo-image {
    max-width: 220px;
    object-fit: contain; }
  .premium-contenttitleEn {
    font-size: 14px !important;
    margin-top: -4px; }
  .content-type-logo {
    height: 10px !important; }
  .content-type-name {
    font-size: 10px !important; }
  .watchnow-more-container {
    margin-top: 10px; }
  .synopsis-inner-container {
    font-size: 10px;
    line-height: 15px;
    width: 190px; }
  .synopsis-inner-containerAr {
    font-size: 10px;
    line-height: 15px;
    width: 190px; }
  .premiumPageSlider {
    width: 100%;
    height: auto;
    object-fit: cover; }
  .Premium-layout-container {
    width: 100%;
    height: auto; }
  .parent_container {
    margin-left: 35px;
    margin-right: 35px;
    width: auto; }
  .premium-pageD {
    height: auto !important;
    width: 100% !important; }
  .planspage-gradient {
    margin-top: -35%; }
  .planspage-gradientAr {
    margin-top: -38%; }
  .carouseldashes {
    margin-top: 0;
    right: 2%; }
  .carouseldashesAr {
    margin-top: 0;
    left: 2%; }
  .premium-contenttitleEnAR {
    font: normal normal bold 27px / 21px Changa; }
  .premium-contentTitleArabic {
    font: normal normal bold 27px / 21px Changa; }
  .watch-now-button {
    width: 144px !important;
    height: 30px !important;
    font-size: 10px !important; }
  .watch-now-buttonAr {
    width: 144px !important;
    height: 30px !important;
    font-size: 10px !important; }
  .premium-more-button {
    height: 30px !important; }
  .premium-more-morebuttonAr {
    height: 30px !important; } }

@media screen and (min-width: 1024px) and (max-width: 1367px) {
  .parent_container {
    margin-left: 4%; }
  .carouseldashes {
    right: 4%;
    bottom: 13%; }
  .carouseldashesAr {
    left: 4%; } }

@media screen and (min-width: 1024px) and (max-width: 1367px) and (orientation: landscape) {
  .carouseldashes {
    right: 5.6%;
    bottom: 12%; }
  .premium-pageD {
    height: 726px; }
  .planspage-gradient {
    bottom: 37%; }
  .carouseldashesAr {
    left: 5.6%;
    bottom: 13%; } }

@media only screen and (max-width: 767px) {
  .parent_container {
    width: 100%;
    margin-left: 0px;
    bottom: -30%;
    top: auto;
    margin-right: 0px; }
  .Premium-layout-container {
    width: 100%; }
  h2.premium-contenttitleEn {
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    font-weight: bold;
    margin-top: -10px; }
  .watchnow-more-container {
    gap: 8px; }
  .watchnow-more-container,
  .content-type-image {
    justify-content: center; }
  .carouseldashes {
    margin-top: 0;
    justify-content: center;
    margin-right: 0;
    bottom: -34%;
    position: absolute;
    /* margin-bottom: -67%; */
    width: 100%; }
  .carouseldashesAr {
    margin-top: 0;
    justify-content: center;
    margin-right: 0;
    bottom: -34%;
    position: absolute;
    /* margin-bottom: -67%; */
    width: 100%; }
  .premium-thumbnail {
    margin-left: 2px; }
  .premium-thumbnailAr {
    margin-right: 2px; }
  .content-type-logo {
    filter: brightness(0) invert(1);
    height: 15px;
    border-radius: 1px !important; }
  .premiumPageSlider {
    height: 520px !important;
    width: 100vw;
    transform: scale(1.2) translate(0%, 37.5%);
    object-fit: cover;
    /* position: relative; */ }
  .Premium-layout-container {
    top: -10.5em;
    position: relative; }
  .premium-pageD {
    height: auto !important;
    width: 100% !important; }
  .premium-contenttitleEnAR {
    z-index: 1;
    word-wrap: break-word;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
    padding: 0 20px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font: normal normal bold 32px / 21px Changa !important;
    position: relative; }
  .premium-contentTitleArabic {
    z-index: 1;
    word-wrap: break-word;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
    padding: 0 20px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font: normal normal bold 32px / 21px Changa !important;
    position: relative; }
  .watch-now-button {
    width: 159px !important;
    height: 34px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
    background-size: cover;
    text-align: center;
    font: normal normal bold 15px / 33px Changa;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 30px;
    border: none;
    cursor: pointer; }
  .watch-now-buttonAr {
    width: 159px !important;
    height: 34px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0% 0% no-repeat padding-box;
    background-size: cover;
    text-align: center;
    font: normal normal bold 15px / 33px Changa;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 30px;
    border: none;
    cursor: pointer; }
  .premium-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: white;
    height: 34px;
    border: none;
    outline: none;
    cursor: pointer; }
  .premium-more-morebuttonAr {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: white;
    height: 34px;
    border: none;
    outline: none;
    cursor: pointer; }
  .planspage-gradient {
    background: transparent linear-gradient(178deg, rgba(15, 24, 34, 0) 20%, #0f1822 80%) 83% 24% no-repeat padding-box;
    width: 100%;
    height: 527px;
    top: 35em;
    position: absolute; }
  .planspage-gradientAr {
    background: transparent linear-gradient(178deg, rgba(15, 24, 34, 0) 20%, #0f1822 80%) 83% 24% no-repeat padding-box;
    width: 100%;
    height: 527px;
    top: 35em;
    position: absolute; }
  .content-type-name {
    font-size: 15px !important; }
  .premium-logos {
    max-width: 250px;
    left: unset;
    right: unset; }
  .google-ad.google-ad-big {
    display: block; } }
