/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.myactivity-container {
  background-color: #0F1822;
  color: white;
  font-family: "changa";
  min-height: calc(100vh - 80px - 185px); }
  .myactivity-container .top-container {
    display: flex;
    flex-direction: column; }
    .myactivity-container .top-container .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 82px;
      z-index: 99; }
      .myactivity-container .top-container .button-container .watching-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 141px;
        height: 46px;
        border-radius: 5px;
        margin: 20px 5px;
        cursor: pointer; }
        .myactivity-container .top-container .button-container .watching-button .play-icon {
          width: 25px;
          height: 25px;
          background: url("~app/resources/assets/login/play-icon.svg") no-repeat;
          background-size: cover;
          margin: 0 4px; }
        .myactivity-container .top-container .button-container .watching-button .watching-text {
          font-size: 20px;
          font-weight: 400;
          margin: 0 4px; }
      .myactivity-container .top-container .button-container .rating-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 109px;
        height: 46px;
        border-radius: 5px;
        margin: 20px 5px;
        cursor: pointer; }
        .myactivity-container .top-container .button-container .rating-button .star-icon {
          width: 14px;
          height: 14px;
          background: url("~app/resources/assets/login/star-icon.svg") no-repeat;
          background-size: cover;
          margin: 0 4px; }
        .myactivity-container .top-container .button-container .rating-button .rating-text {
          font-size: 20px;
          font-weight: 400;
          margin: 0 4px; }
    .myactivity-container .top-container .myactivity-heading {
      margin-top: 20px;
      margin-bottom: 20px;
      color: #ffffff;
      font-size: 24px;
      text-align: center; }
  .myactivity-container .data-container {
    margin-bottom: 100px; }
  .myactivity-container .rating-details-container .no-content,
  .myactivity-container .watching-details-container .no-content {
    margin-top: 50px;
    text-align: center; }
  .myactivity-container .rating-details-container .rating-details,
  .myactivity-container .rating-details-container .watching-details,
  .myactivity-container .watching-details-container .rating-details,
  .myactivity-container .watching-details-container .watching-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    .myactivity-container .rating-details-container .rating-details .rating-row,
    .myactivity-container .rating-details-container .rating-details .watching-row,
    .myactivity-container .rating-details-container .watching-details .rating-row,
    .myactivity-container .rating-details-container .watching-details .watching-row,
    .myactivity-container .watching-details-container .rating-details .rating-row,
    .myactivity-container .watching-details-container .rating-details .watching-row,
    .myactivity-container .watching-details-container .watching-details .rating-row,
    .myactivity-container .watching-details-container .watching-details .watching-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      font-weight: 400;
      overflow: hidden; }
      .myactivity-container .rating-details-container .rating-details .rating-row .top-border,
      .myactivity-container .rating-details-container .rating-details .watching-row .top-border,
      .myactivity-container .rating-details-container .watching-details .rating-row .top-border,
      .myactivity-container .rating-details-container .watching-details .watching-row .top-border,
      .myactivity-container .watching-details-container .rating-details .rating-row .top-border,
      .myactivity-container .watching-details-container .rating-details .watching-row .top-border,
      .myactivity-container .watching-details-container .watching-details .rating-row .top-border,
      .myactivity-container .watching-details-container .watching-details .watching-row .top-border {
        position: absolute;
        width: 706px;
        height: 1px;
        background-color: #d7d7d7;
        opacity: 0.2; }
      .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content,
      .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content,
      .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content,
      .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content,
      .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content,
      .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content,
      .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content,
      .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content {
        display: flex;
        flex-direction: row; }
        .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .left-container,
        .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .left-container,
        .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .left-container,
        .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .left-container,
        .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .left-container,
        .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .left-container,
        .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .left-container,
        .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .left-container {
          display: flex;
          flex-direction: row; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .left-container .viewed,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .left-container .viewed,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .left-container .viewed,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .left-container .viewed,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .left-container .viewed,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .left-container .viewed,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .left-container .viewed,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .left-container .viewed {
            width: 130px; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .left-container .title,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .left-container .title,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .left-container .title,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .left-container .title,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .left-container .title,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .left-container .title,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .left-container .title,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .left-container .title {
            width: 360px;
            cursor: pointer; }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .left-container .title:hover,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .left-container .title:hover,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .left-container .title:hover,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .left-container .title:hover,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .left-container .title:hover,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .left-container .title:hover,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .left-container .title:hover,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .left-container .title:hover {
              text-decoration: underline; }
        .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container,
        .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container,
        .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container,
        .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container,
        .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container,
        .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container,
        .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container,
        .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container {
          display: flex;
          flex-direction: row; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .report-problem,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .report-problem,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .report-problem,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .report-problem,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .report-problem,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .report-problem,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .report-problem,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .report-problem {
            width: 175px;
            cursor: pointer; }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .report-problem:hover,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .report-problem:hover {
              text-decoration: underline; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container {
            display: flex;
            align-items: center; }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .icon,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .icon {
              margin: 0 10px;
              width: 15px;
              height: 15px; }
              @media only screen and (max-width: 745px) {
                .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .icon,
                .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .icon {
                  margin: 0 5px; } }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .set,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .set {
              background: url("~app/resources/assets/login/star-icon-unfocused.svg") no-repeat; }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .rating-container .unset,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .rating-container .unset {
              background: url("~app/resources/assets/login/star-icon.svg") no-repeat; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .cross-icon,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .cross-icon {
            width: 20px;
            height: 60px;
            margin: 0 10px;
            background: url("~app/resources/assets/login/cross-icon.svg") center no-repeat;
            cursor: pointer; }
            @media only screen and (max-width: 745px) {
              .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .cross-icon,
              .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .cross-icon {
                background: url("~app/resources/assets/login/cross-mobile.svg") center no-repeat; } }
        @media only screen and (max-width: 745px) {
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content {
            width: 100%;
            font-size: 16px; } }
  .myactivity-container .rodal-dialog {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .myactivity-container .rodal-dialog .rating-dialog-content .dialog-title {
      text-align: center;
      margin: 30px auto !important;
      color: #606060;
      font-size: 22px;
      letter-spacing: 0.005em; }
    .myactivity-container .rodal-dialog .rating-dialog-content .rating-container {
      text-align: center; }
      .myactivity-container .rodal-dialog .rating-dialog-content .rating-container .icon {
        margin: 0 10px;
        width: 15px;
        height: 15px; }
      .myactivity-container .rodal-dialog .rating-dialog-content .rating-container .star {
        margin: 5px;
        display: inline-block;
        background-size: contain;
        height: 50px;
        width: 50px;
        content: ""; }
      .myactivity-container .rodal-dialog .rating-dialog-content .rating-container .dv-star-rating-full-star .star {
        background-image: url("~app/resources/assets/thumbnail/ic-star.svg"); }
      .myactivity-container .rodal-dialog .rating-dialog-content .rating-container .dv-star-rating-empty-star .star {
        background-image: url("~app/resources/assets/rating/empty-star.png"); }
    .myactivity-container .rodal-dialog .rating-dialog-actions .dialog-ok-btn {
      border-top: 1px solid #606060;
      width: 100%;
      text-transform: none;
      color: #606060 !important;
      padding: 0;
      margin: 0 0 -17px !important;
      border-radius: 0;
      min-height: inherit;
      margin: 0 !important;
      text-decoration: none;
      letter-spacing: 0.01em;
      display: inline-block;
      align-items: center;
      text-align: center;
      font-size: 14px;
      height: 50px; }
      .myactivity-container .rodal-dialog .rating-dialog-actions .dialog-ok-btn:first-child {
        border-right: 1px solid #606060; }
      .myactivity-container .rodal-dialog .rating-dialog-actions .dialog-ok-btn:hover {
        background: #ff740f;
        color: white; }
    .myactivity-container .rodal-dialog .dialog-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .myactivity-container .rodal-dialog .dialog-content .content {
        padding: 10px;
        text-align: center; }
      .myactivity-container .rodal-dialog .dialog-content .dialog-title {
        color: #ff740f;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.005em;
        margin-bottom: 10px; }
      .myactivity-container .rodal-dialog .dialog-content .dialog-text {
        color: #606060; }
      .myactivity-container .rodal-dialog .dialog-content .actions {
        display: flex;
        height: 36px; }
      .myactivity-container .rodal-dialog .dialog-content .dialog-ok-btn {
        border-top: 1px solid #606060;
        width: 100%;
        text-transform: none;
        color: #606060;
        padding: 0;
        margin: 0 0 -17px;
        border-radius: 0;
        background: #fff;
        font-size: 14px;
        height: 36px; }
        .myactivity-container .rodal-dialog .dialog-content .dialog-ok-btn:first-child {
          border-right: 1px solid #606060; }
        .myactivity-container .rodal-dialog .dialog-content .dialog-ok-btn:hover {
          background: #ff740f;
          color: white; }
    @media only screen and (max-width: 745px) {
      .myactivity-container .rodal-dialog {
        width: 90% !important; } }
  .myactivity-container .done-button {
    margin: 0 auto 80px;
    width: 109px;
    height: 46px;
    line-height: 46px;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
    background-color: #ff740f;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer; }
  .myactivity-container .prob-watch-button {
    margin: 0 auto 80px;
    width: 109px;
    height: 46px;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
    background-color: #ff740f;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer; }
    .myactivity-container .prob-watch-button.margin-button {
      margin: 0;
      margin-right: 10px;
      margin-top: 5px; }
  .myactivity-container .empty-space {
    height: 30px; }
  .myactivity-container.watching .button-container .watching-button {
    background: #ff740f; }
  .myactivity-container.watching .button-container .rating-button {
    background: #ebebeb;
    color: #888888; }
    .myactivity-container.watching .button-container .rating-button .star-icon {
      background-image: url("~app/resources/assets/login/star-icon-unfocused.svg"); }
  .myactivity-container.rating .button-container .watching-button {
    color: #888888;
    background: #ebebeb; }
    .myactivity-container.rating .button-container .watching-button .play-icon {
      background-image: url("~app/resources/assets/login/play-icon-unfocused.svg"); }
  .myactivity-container.rating .button-container .rating-button {
    background: #ff740f; }
  .myactivity-container .problem-watching-container {
    display: flex;
    justify-content: center; }
    .myactivity-container .problem-watching-container .problem-watching {
      width: calc(100% - 20vw); }
      .myactivity-container .problem-watching-container .problem-watching .problem-watch-heading {
        color: #ffffff;
        font-size: 24px; }
    .myactivity-container .problem-watching-container .problem-watch-first-row {
      display: flex; }
      .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-checkbox-container {
        order: 1;
        flex-basis: 70%; }
        @media only screen and (max-width: 745px) {
          .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-checkbox-container {
            order: 2; } }
      .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-image-container {
        order: 2;
        flex-basis: 30%; }
        .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-image-container .image-container img {
          width: 100%; }
        .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-image-container .play-button img {
          display: none; }
        @media only screen and (max-width: 745px) {
          .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-image-container {
            order: 1; }
            .myactivity-container .problem-watching-container .problem-watch-first-row .problem-watch-image-container .image-thumbnail .play-button {
              display: none; } }
      @media only screen and (max-width: 745px) {
        .myactivity-container .problem-watching-container .problem-watch-first-row {
          flex-direction: column; } }
    .myactivity-container .problem-watching-container .comment-textarea {
      width: 100%;
      border-radius: 5px;
      resize: none; }
    .myactivity-container .problem-watching-container .problem-watch-buttons {
      display: flex; }
  .myactivity-container .error-text {
    height: 13px;
    font-size: 13px;
    font-weight: 400;
    margin: 5px 0 10px 0;
    color: #ff0000; }

/*Device width pixel lesser than 1024*/
@media only screen and (max-width: 745px) {
  .myactivity-container {
    min-height: calc(100vh - 80px - 185px); }
    .myactivity-container .top-container {
      flex-direction: column-reverse; }
      .myactivity-container .top-container .button-container {
        padding: 0 20px;
        justify-content: center; }
      .myactivity-container .top-container .myactivity-heading {
        padding: 0 20px;
        text-align: left; }
    .myactivity-container .rating-details-container .rating-details,
    .myactivity-container .rating-details-container .watching-details,
    .myactivity-container .watching-details-container .rating-details,
    .myactivity-container .watching-details-container .watching-details {
      padding: 0 20px; }
      .myactivity-container .rating-details-container .rating-details .rating-row,
      .myactivity-container .rating-details-container .rating-details .watching-row,
      .myactivity-container .rating-details-container .watching-details .rating-row,
      .myactivity-container .rating-details-container .watching-details .watching-row,
      .myactivity-container .watching-details-container .rating-details .rating-row,
      .myactivity-container .watching-details-container .rating-details .watching-row,
      .myactivity-container .watching-details-container .watching-details .rating-row,
      .myactivity-container .watching-details-container .watching-details .watching-row {
        height: unset;
        line-height: unset;
        width: 100%; }
        .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content,
        .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content,
        .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content,
        .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content,
        .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content,
        .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content,
        .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content,
        .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content {
          color: #646464; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .left-container,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .left-container,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .left-container,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .left-container,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .left-container,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .left-container,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .left-container,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .left-container {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            width: 100%; }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .left-container .title,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .left-container .title,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .left-container .title,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .left-container .title,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .left-container .title,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .left-container .title,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .left-container .title,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .left-container .title {
              width: unset; }
          .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container,
          .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container,
          .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container,
          .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container,
          .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container,
          .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container,
          .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container,
          .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end; }
            .myactivity-container .rating-details-container .rating-details .rating-row .watching-row-content .right-container .report-problem,
            .myactivity-container .rating-details-container .rating-details .watching-row .watching-row-content .right-container .report-problem,
            .myactivity-container .rating-details-container .watching-details .rating-row .watching-row-content .right-container .report-problem,
            .myactivity-container .rating-details-container .watching-details .watching-row .watching-row-content .right-container .report-problem,
            .myactivity-container .watching-details-container .rating-details .rating-row .watching-row-content .right-container .report-problem,
            .myactivity-container .watching-details-container .rating-details .watching-row .watching-row-content .right-container .report-problem,
            .myactivity-container .watching-details-container .watching-details .rating-row .watching-row-content .right-container .report-problem,
            .myactivity-container .watching-details-container .watching-details .watching-row .watching-row-content .right-container .report-problem {
              align-items: center;
              display: flex;
              width: unset; }
        .myactivity-container .rating-details-container .rating-details .rating-row .top-border,
        .myactivity-container .rating-details-container .rating-details .watching-row .top-border,
        .myactivity-container .rating-details-container .watching-details .rating-row .top-border,
        .myactivity-container .rating-details-container .watching-details .watching-row .top-border,
        .myactivity-container .watching-details-container .rating-details .rating-row .top-border,
        .myactivity-container .watching-details-container .rating-details .watching-row .top-border,
        .myactivity-container .watching-details-container .watching-details .rating-row .top-border,
        .myactivity-container .watching-details-container .watching-details .watching-row .top-border {
          width: 100%;
          left: 0; } }
